/*** Date range picker **/
.DateRangePicker_picker {
    z-index: 10;
}
.DateInput{
  margin-left: 0.75rem;
}
.DateInput_input{
  padding: 0.375rem 0.75rem .375em 0em;
  font-size: 0.875rem;
  color: #5c6873;
  font-weight: 400;
  height: calc(2.0625rem);
}
.SingleDatePicker{
  display: block;
}
.SingleDatePickerInput{
  width: 100%;
}
.DateInput_input__focused{
  border-bottom: 0px;
}
.SingleDatePickerInput__withBorder{
  border-radius: 0.25rem;
}
