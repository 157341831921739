/***** Media gallery *******/
//_variables
$breadcrumb-height: 70px !default;
$heading-title-bar-height: 42px !default;
$heading-button-bar-height: 35px !default;
$search-bar-height: 50px !default;
$custom-card-footer-height: 50px !default;

$drop-zone-height: 216px !default;
$information-button: 110px !default;

$modal-header:59px !default;
$modal-body-padding:16px !default;
$modal-nav-tabs:39px !default;
$modal-footer-height:117px !default;
$adjustment-margin:8rem !default;

/** Cropper **/
.cropper-wrapper{
  min-height: 200px;
  max-height: calc(100vh - 300px);
  max-width: 100%;
  img{
    width: 100%;
  }
}

/** Gallery **/
.gallery-container{
  overflow-y: scroll;
  height: calc(100vh - 200px);
  position: relative;
}

.gallery-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  border: 2px solid;
  //margin-right: 0.5rem;
  padding:0.2rem;
  position: relative;
  cursor: pointer;
}

.gallery-wrapper.selected{
  border: 4px solid;
}

@include media-breakpoint-down(sm) {
.gallery-preview-container{
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
 }
}
@include media-breakpoint-up(sm) {
  .gallery-preview-container{
      justify-content: left;
      display: block;
      align-items: left;
   }
}

.gallery-preview-image{
  width: 90px;
  height: 90px;
  background-position: center center;
  background-size: cover;
}

.mini-image{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  margin-right: 0.5rem;
  background-size: cover;
  position: relative;
  cursor: pointer;
  border: 2px solid;
}
/** Image library */
.media-images-bar{
  width: 66.66666%;
  float: left;
}
.media-information-bar{
  width: 33.33333%;
  height: 100%;
  float:right;
  background-color: #f0f3f5;
}
.media-information-body{
  height: 100%
}
.media-image-container{
  overflow-y: auto;
  height: calc(100vh
  - #{$navbar-height}
  - #{$breadcrumb-height}
  - #{$heading-title-bar-height}
  - #{$heading-button-bar-height}
  - #{$search-bar-height}
  - #{$custom-card-footer-height}
  - #{$footer-height}
  );
  position: relative;
  min-height: 200px;
  background-color: #fff;
}

.media-images-header, .media-information-header{
  background-color: $gray-100;
  height: $search-bar-height;
}
.custom-card-footer{
  background-color: $gray-100;
  height: $custom-card-footer-height;
}
.media-image-container.with-uploader{
  height: calc(100vh
  - #{$navbar-height}
  - #{$breadcrumb-height}
  - #{$heading-title-bar-height}
  - #{$heading-button-bar-height}
  - #{$search-bar-height}
  - #{$custom-card-footer-height}
  - #{$footer-height}
  - #{$drop-zone-height}
  );
}

.media-library-modal-wrapper{
  .media-image-container{
    height: calc(100vh
    - #{$adjustment-margin}
    - #{$modal-header}
    - #{$modal-body-padding}
    - #{$modal-nav-tabs}
    - #{$search-bar-height}
    - #{$custom-card-footer-height}
    - #{$modal-footer-height}
    );
    min-height: 340px;
  }

  @include media-breakpoint-up(sm) {
    .media-image-container{
      min-height: 200px;
      max-height: 300px;
    }
  }
  @include media-breakpoint-up(md) {
    .media-image-container{
        min-height: 200px;
        max-height: 400px;
    }
  }
  @include media-breakpoint-up(lg) {
    .media-image-container{
        min-height: 300px;
        max-height: 350px;
    }
  }
  @include media-breakpoint-up(xl) {
    .media-image-container{
        min-height: 300px;
        max-height: 400px;
    }
  }
  .custom-card-footer{
    display: none;
  }
  .cropper-wrapper{
    max-height: calc(100vh - 450px);
  }
}

.image-edit-modal-body{
  overflow-y: scroll;
  /*height: calc(100vh - 200px);*/
  min-height: 300px;
  height:100%;
  position: relative;
}

.media-image-cropper-container{
  width: 100%;
  max-height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
}

.image-modal-container{
  overflow-y: scroll;
  height: calc(100vh - 350px);
  position: relative;
}

.media-image-loader, .media-image-loaded, .media-image-loading{
  /*height: 200px; @If LazyLoad component used*/
  margin: 0.5rem;
}
.media-image-wrapper{
  height: 160px;
  width: 160px;
  border: 2px solid;
  background-size: cover;
  background-color: $gray-100;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-image-wrapper.selected, .gallery-wrapper.selected{
  border: 3px solid;
}
@include media-breakpoint-down(sm) {
.media-image-wrapper{
    width: 100%;
  }
}
@include media-breakpoint-up(sm) {
  .media-image-wrapper{
      width: 160px;
  }
}

.image-check-box{
  position: absolute;
  top: 0;
  right: 0;
}

.footer-image-check-box{
  font-size: .5rem;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

/** Dropzone image upload **/

.dropzone-container{
  background-color: white;
  outline: 2px dashed $gray-300;
  .close-btn{
    position: absolute;
    right: 1rem;
  }
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.dropzone--isActive{
  background-color: $gray-100;
}

.media-media-image-wrapper, .media-media-image-wrapper img{
  height: 100px;
  width: 100%;
}

/*** Cover image **/
.cover-image-wrapper{
  height: 200px;
  width: 100%;
  border: 2px solid;
  background-size: cover;
  background-color: $gray-100;
  cursor: pointer;
}

/** Gallery preview block **/
.gallery-responsive-block{
  .gallery-wrapper{
    width: 100%;
  }
  .gallery-preview-content{
    width: 100%;
  }
  .gallery-preview-image{
    width: 48%;
  }
}
