/***** Buttons *******/

@include media-breakpoint-down(sm) {
  .btn-facebook span{
      font-size: .795rem;
  }
}
.btn-apple{
  height: 42px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.btn-apple, .btn-apple:hover, .btn-apple:not(:disabled):not(.disabled):active{
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-apple{
  div{
    max-width: 100% !important;
  }
}

.btn-circle {
  width: 20px;
  height: 20px;
  padding: 2px 0px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  font-weight: 200;
  line-height: 1.42857;
  color: #999999;
}
