// Borders
.border-bottom-dotted{
  border-bottom-style: dotted;
}
// Text styles
.table-cell-text-overflow-ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  display:initial;
}
//Height
.height-0{
  height: 0px !important;
}

//Line Height
.line-height-1{
  line-height:1;
}
.line-height-2{
  line-height:2;
}

//Vertical alignment
.vertical-align-middle{
  vertical-align: middle;
}

//Modal
.modal-custom-large{
  max-width: 95%;
}

//Container
.empty-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

//Scrollbar
div.custom-scrollbar::-webkit-scrollbar-track{
  background: $gray-200;
}
div.custom-scrollbar::-webkit-scrollbar{
  width: 10px;
}
div.custom-scrollbar::-webkit-scrollbar-thumb{
  background: $gray-400;
}
div.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: $gray-500;
}

//Image
.cover-image img{
  border-radius:0;
}

.border-1{
  border-width: 1px !important;
}

.border-2{
  border-width: 2px !important;
}

.border-3{
  border-width: 3px !important;
}
.status-indicator{
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
