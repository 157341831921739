/***** Tickets *******/

.image-preview{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  margin-right: 0.5rem;
  background-size: cover;
  position: relative;
  cursor: pointer;
  border: 2px solid;
}

.user_block{
  color: #808080;
}
.avatar-container .img-avatar{
  height: 35px;
  margin-right: 10px;
}

.commentbox-container{
  .form-group{
    margin-bottom: 0px !important;
  }
}
