/***** NavBars *******/
.app-header .navbar-brand{
  width:200px;
}
@include media-breakpoint-down(sm) {
  .app-header .navbar-brand{
    margin-left: -100px;
  }
}
.sidebar .nav-dropdown-items .nav-item{
  padding-left: 0.75rem;
}


// Header with alertbar (for user not activated)
.app .header-alert{
  width: 100%;
  position: absolute;
  z-index: 999;
  padding: 5px 10px;
  top:0;
  .close{
    padding: 5px 10px;
  }
  .btn-link{
    padding: 0px;
    vertical-align: baseline;
  }
}
.with-message-bar .app-header{
  top: 33px;
}
.with-message-bar .app-body{
  margin-top: 88px;
}
.with-message-bar .sidebar{
  height: calc(100vh - 88px) !important;
}
