/***** Table *******/

.btn-table-action{
  padding:0.375rem 0.75rem;
}
.react-bs-table-sizePerPage-dropdown button{
  height: calc(1.648438rem + 2px);
  padding: 0px 12px;
}
.table-size-dropdown{
  width: auto;
  display: inline-block;
}
.react-bs-table table td div, .react-bs-table table th div{
  text-overflow: ellipsis;
  overflow: hidden;
  display:initial;
}
.react-bs-table table td .img-avatar{
  height: 35px;
}

/** Custom Responsive Table **/
.bootstrap-table-responsive{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  .table-head-row{
    border-top: 1px solid #c8ced3;
    border-bottom:2px solid #c8ced3;
    padding: 0.75em;
    font-weight: bold;
    cursor: pointer;
  }
  .table-head-col{
    padding-top:.75em;
    span.not-sorted{
      color:rgb(204, 204, 204);
    }
  }

  .table-body-row{
    padding: 0.75em;
    border-top: 1px solid #c8ced3;
  }
  .table-body .table-body-row:nth-of-type(odd){
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-body .table-body-row:nth-of-type(even){
    background-color: rgba(0, 0, 0, 0.1);
  }
  .pagination-container{
    margin-top: 10px;
  }

}

@include media-breakpoint-up(sm) {
  .table-head-col{
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table-body-col{
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .table-body-col.avatar-col{
      text-overflow: clip !important;
  }
}
@include media-breakpoint-down(sm) {
  .bootstrap-table-responsive{
    .img-avatar{
      height: 100px;
    }
  }
}

/** Custom Responsive Table -- ENDS **/

/*** Responsive Table - small screens **/
@include media-breakpoint-down(sm) {
  .responsive-table{
    .react-bs-table table {
        display: table;
        colgroup, thead {
          display: none;
        }
        tbody, tr, tr>td, tr>th {
            width: auto!important;
            display: block!important;
            border: 0px;
        }
    }
  }
}

/** Table Toolbar **/
.table-toolbar-button{
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 3px;
}
.table-form-group{
  display: inline-block;
  margin-bottom: 1rem;
}
.table-search-box{
  width: 0px;
  padding: 0px;
  border: 0px;
 -webkit-transition: width 0.4s ease-out;
 transition: width 0.4s ease-out;
}
.table-search-box.in-active{
  width: 0px;
  padding: 0px;
  border: 0px;
}
.table-search-box.active{
  width:235px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #e4e7ea;
}

//Badge style
.mobile .badge{
  line-height: 1.5;
}

//order product list - in detailed view
.order-prod-cover-image .img-avatar{
  height: 50px;
}
