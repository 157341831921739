/***** Analytics page styles *******/
.bg-filter-bar{
  background-color: $gray-100;
}
//Summary Blocks
.summaryblock-icon{
  font-size: 1.5rem;
}

//Favorite dashboard
.dashboard-table .bootstrap-table-responsive .table-body .table-body-row:nth-of-type(odd){
  background-color: rgba(0, 0, 0, 0.15);
}

.analytics-summary-loader{
  .sk-circle {
    margin: 20px auto;
    width: 20px;
    height: 20px;
    position: relative;
  }
}
