/***** Google maps *******/
.places-list{
  z-index: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  cursor: pointer;
  margin-top: $input-height;
  padding: 0px 15px;
}

@each $color, $value in $theme-colors {
  //normal style
  .input-outline-#{$color} {
    border-color: #{$value};
  }
}
.list-header{
  background-color: $gray-100;
}
