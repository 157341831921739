/***** Layout *******/
//Image loader
.img-loader{
  background-color: #bbb;
  width:35px;
}
// Page loader
.loader-container{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.loader-container.with-breadcrumb{
  margin-top: -70px;
}
.loader-container.without-breadcrumb{
  margin-top: 0px;
}

// Full page layout styles
.fullpage-footer{
  margin-left: 0px !important;
}
.fullpage-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width : 768px)  {
 .fullpage-container .card{
   min-width: 600px;
 }
}
