/***** Fanwall module styles *******/
.fanwall-media{
  .fanwall-carousel-item{
    height: 150px;
  }

  @include media-breakpoint-up(md) {
    .fanwall-carousel-item{
      height: 300px;
    }
  }
  .carousel-control-prev, .carousel-control-next{
    opacity: 1;
    cursor: pointer;
  }
}

.fan-wall-post-list .search-bar{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
