/*** Sections **/
//Draggable section

@include media-breakpoint-down(sm) {
  .draggable-section-container, .draggable-attribute_container{
    padding: 0px !important;
  }
  .draggable-section, .draggable-attribute{
    padding: 0px !important;
  }
}
