/***** Image uploader *******/
.image-upload-input{
  input{
    display: none;
  }
  label{
    display: inline-block;
    margin-bottom: 0px;
  }
}

.image-cropper-container{
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
}

.image-wrapper, .image-wrapper img{
  height: 100px;
}
