/***** Notification page *******/

// Message preview block
.notification-preview{
  background-image: url("/assets/img/preview/phone-preview.svg");
  padding: 0.25rem;
  max-width: 375px;
  height: 100%;
  background-size: cover;

  .notification-text{
      width: auto;
      max-width: 90%;
      height: auto;
      margin: 50% 5% 0px;
      box-shadow: rgb(212, 212, 213) 0px 1px 3px 0px;
      background-color: rgb(255, 255, 255);
      overflow-y: hidden;
      border-radius: 10px;

      .title{
        color: rgb(85, 85, 85);
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        font-weight: 300;
        text-align: center;
        line-height: 20px;
        padding: 10px 10px 8px;
        border-bottom: none;
        border-radius: 10px 10px 0px 0px;
      }
      .body{
        padding: 10px;
      }
  }
}
