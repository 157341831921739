/*** Payment Block **/
.text-hint{
  font-size: 0.75rem;
}
.FormRowInput{
  margin-left: 5px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}
.FormRow{
  margin-bottom: 1rem;
}

.ErrorMessage{
  color:#f86c6b !important;
}

.modal-header.payment-modal{
  background-color: black !important;
  color: #fff !important;

  .modal-title{
    width: 100%;
  }

  .price-text{
    font-weight: 900;
  }

  .close{
    color: #fff !important;
  }
}

//Stripe card element
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  background-color: white;
  width: 100%;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement--focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #8ad4ee;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.StripeElement--invalid {
  border-color: rgb(239, 152, 150);
}

.StripeElement--webkit-autofill {
  background-color: rgb(254, 253, 229) !important;
}
