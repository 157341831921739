/*** Settings Aside preview **/
.progress-wrapper{
  width: 100%;
}
.preview-footer{
  height: $aside-settings-menu-footer;
  border-top: 1px solid #c8ced3;
  align-items: center !important;
  justify-content: center;
  display: flex;
}
.aside-settings-menu{
  width: 350px;
}
.aside-settings-menu-off-canvas .app-header + .app-body .aside-settings-menu{
  height: calc(100vh - 55px)
}
.aside-settings-menu-lg-show.aside-settings-menu-fixed .main{
  margin-right: 350px;
}
/*** Copying CORE UI aside-settings-menu for aside-settings-menu **/
//_aside.scss
.aside-settings-menu {
  z-index: $zindex-sticky - 1;
  width: $aside-settings-menu-width;
  color: $aside-settings-menu-color;
  background: $aside-settings-menu-bg;
  @include borders($aside-settings-menu-borders);

  .nav-tabs {
    border-color: $border-color;
    .nav-link {
      padding: $aside-settings-menu-nav-padding-y $aside-settings-menu-nav-padding-x;
      color: $body-color;
      border-top: 0;
      @include border-radius(0);
      &.active {
        color: theme-color("primary");
        border-right-color: $border-color;
        border-left-color: $border-color;
      }
    }
    .nav-item:first-child {
      .nav-link {
        border-left: 0;
      }
    }
  }

  .tab-content {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border: 0;
    border-top: 1px solid $border-color;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      width: 10px;
      margin-left: -10px;
      appearance: none;
    }

    // &::-webkit-scrollbar-button {    }

    &::-webkit-scrollbar-track {
      background-color: lighten($aside-settings-menu-bg, 5%);
      border-right: 1px solid darken($aside-settings-menu-bg, 5%);
      border-left: 1px solid darken($aside-settings-menu-bg, 5%);
    }

    // &::-webkit-scrollbar-track-piece {    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($aside-settings-menu-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }

    .tab-pane {
      padding: 0;
    }
  }
}

//layout.scss
.aside-settings-menu {
  transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}
.aside-settings-menu {
  position: fixed;
  height: 100%;
}
.app-body {
  .aside-settings-menu {
    // $aside-settings-menu-width is the width of the columns
    flex: 0 0 $aside-settings-menu-width;
  }
}

html:not([dir="rtl"]) {
  .aside-settings-menu {
    right: 0;
    margin-right: - $aside-settings-menu-width;
  }
}
html[dir="rtl"] {
  .aside-settings-menu {
    left: 0;
    margin-left: - $aside-settings-menu-width;
  }
}
.aside-settings-menu-fixed {
  .aside-settings-menu {
    position: fixed;
    height: 100%;

    .tab-content {
      height: calc(100vh  - #{$aside-settings-menu-footer} - #{$navbar-height});
    }
  }
}

@include media-breakpoint-up(lg) {
  //
  // Aside Menu
  //
  .aside-settings-menu-fixed {
    .aside-settings-menu {
      position: fixed;
      height: 100%;

      .tab-content {
        height: calc(100vh  - #{$aside-settings-menu-footer} - #{$navbar-height});
      }
    }
  }

  .aside-settings-menu-fixed {
    .app-header + .app-body .aside-settings-menu {
      height: calc(100vh - #{$navbar-height});
    }
  }

  .aside-settings-menu-off-canvas {
    .aside-settings-menu {
      position: fixed;
      z-index: $zindex-sticky - 1;
      height: 100%;
    }
  }

  .aside-settings-menu-off-canvas {
    .app-header + .app-body .aside-settings-menu {
      height: calc(100vh - #{$navbar-height});
    }
  }

  html:not([dir="rtl"]) {
    .aside-settings-menu-fixed,
    .aside-settings-menu-off-canvas {
      .aside-settings-menu {
        right: 0;
      }
    }
  }
  html[dir="rtl"] {
    .aside-settings-menu-fixed,
    .aside-settings-menu-off-canvas {
      .aside-settings-menu {
        left: 0;
      }
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    html:not([dir="rtl"]) {
      .sidebar#{$infix}-show,
      .sidebar-show {
        .sidebar {
          margin-left: 0;
        }
        @if $breakpoint != "xs" {
          &.sidebar-fixed {
            .main,
            .app-footer {
              margin-left: $sidebar-width;
            }
            &.sidebar-compact {
              .main,
              .app-footer {
                margin-left: $sidebar-compact-width;
              }
            }
            &.sidebar-minimized {
              .main,
              .app-footer {
                margin-left: if($breakpoint != "lg" and $breakpoint != "xl", $sidebar-width, $sidebar-minimized-width);
              }
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              left: $sidebar-width;
            }
            &.sidebar-compact {
              .breadcrumb {
                left: $sidebar-compact-width;
              }
            }
            &.sidebar-minimized {
              .breadcrumb {
                left: $sidebar-minimized-width;
              }
            }
          }
        }
      }

      .aside-settings-menu-show,
      .aside-settings-menu#{$infix}-show {
        .aside-settings-menu {
          margin-right: 0;
        }
        @if $breakpoint != "xs" {
          &.aside-settings-menu-fixed {
            .main,
            .app-footer {
              margin-right: $aside-settings-menu-width;
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              right: $aside-settings-menu-width;
            }
          }
        }
      }
    }

    html[dir="rtl"] {
      .sidebar#{$infix}-show,
      .sidebar-show {
        .sidebar {
          margin-right: 0;
        }
        @if $breakpoint != "xs" {
          &.sidebar-fixed {
            .main,
            .app-footer {
              margin-right: $sidebar-width;
            }
            &.sidebar-compact {
              .main,
              .app-footer {
                margin-right: $sidebar-compact-width;
              }
            }
            &.sidebar-minimized {
              .main,
              .app-footer {
                margin-right: if($breakpoint != "lg" and $breakpoint != "xl", $sidebar-width, $sidebar-minimized-width);
              }
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              right: $sidebar-width;
            }
            &.sidebar-compact {
              .breadcrumb {
                right: $sidebar-compact-width;
              }
            }
            &.sidebar-minimized {
              .breadcrumb {
                right: $sidebar-minimized-width;
              }
            }
          }
        }
      }

      .aside-settings-menu-show,
      .aside-settings-menu#{$infix}-show {
        .aside-settings-menu {
          margin-left: 0;
        }
        @if $breakpoint != "xs" {
          &.aside-settings-menu-fixed {
            .main,
            .app-footer {
              margin-left: $aside-settings-menu-width;
            }
          }
          &.breadcrumb-fixed {
            .breadcrumb {
              left: $aside-settings-menu-width;
            }
          }
        }
      }
    }

    .sidebar#{$infix}-show,
    .aside-settings-menu#{$infix}-show {
      @keyframes opacity {
        0%   { opacity: 0; }
        100% { opacity: 1; }
      }

      @if $breakpoint == "xs" {
        @include media-breakpoint-down(xs) {
          .main {
            position: relative;
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: $zindex-sticky - 2;
              width: 100%;
              height: 100%;
              content: "";
              background: rgba(0, 0, 0, .7);
              animation: opacity $layout-transition-speed;
            }
          }
        }
      }
    }
  }
}
//theme-dark.scss

@media (max-width: 575.98px) {
  .aside-settings-menu-show .main {
    position: relative;
  }
  .aside-settings-menu-show .main::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1018;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    animation: opacity 0.25s;
  }
}

@media (min-width: 576px) {
  html:not([dir="rtl"]) .aside-settings-menu-show .aside-settings-menu,
  html:not([dir="rtl"]) .aside-settings-menu-sm-show .aside-settings-menu {
    margin-right: 0;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html:not([dir="rtl"]) .aside-settings-menu-sm-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-sm-show.aside-settings-menu-fixed .app-footer {
    margin-right: 350px;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html:not([dir="rtl"]) .aside-settings-menu-sm-show.breadcrumb-fixed .breadcrumb {
    right: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show .aside-settings-menu,
  html[dir="rtl"] .aside-settings-menu-sm-show .aside-settings-menu {
    margin-left: 0;
  }
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html[dir="rtl"] .aside-settings-menu-sm-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-sm-show.aside-settings-menu-fixed .app-footer {
    margin-left: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html[dir="rtl"] .aside-settings-menu-sm-show.breadcrumb-fixed .breadcrumb {
    left: 350px;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  html:not([dir="rtl"]) .aside-settings-menu-show .aside-settings-menu,
  html:not([dir="rtl"]) .aside-settings-menu-md-show .aside-settings-menu {
    margin-right: 0;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html:not([dir="rtl"]) .aside-settings-menu-md-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-md-show.aside-settings-menu-fixed .app-footer {
    margin-right: 350px;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html:not([dir="rtl"]) .aside-settings-menu-md-show.breadcrumb-fixed .breadcrumb {
    right: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show .aside-settings-menu,
  html[dir="rtl"] .aside-settings-menu-md-show .aside-settings-menu {
    margin-left: 0;
  }
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html[dir="rtl"] .aside-settings-menu-md-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-md-show.aside-settings-menu-fixed .app-footer {
    margin-left: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html[dir="rtl"] .aside-settings-menu-md-show.breadcrumb-fixed .breadcrumb {
    left: 350px;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (min-width: 992px) {

  html:not([dir="rtl"]) .aside-settings-menu-show .aside-settings-menu,
  html:not([dir="rtl"]) .aside-settings-menu-lg-show .aside-settings-menu {
    margin-right: 0;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html:not([dir="rtl"]) .aside-settings-menu-lg-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-lg-show.aside-settings-menu-fixed .app-footer {
    margin-right: 350px;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html:not([dir="rtl"]) .aside-settings-menu-lg-show.breadcrumb-fixed .breadcrumb {
    right: 350px;
  }

  html[dir="rtl"] .aside-settings-menu-show .aside-settings-menu,
  html[dir="rtl"] .aside-settings-menu-lg-show .aside-settings-menu {
    margin-left: 0;
  }
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html[dir="rtl"] .aside-settings-menu-lg-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-lg-show.aside-settings-menu-fixed .app-footer {
    margin-left: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html[dir="rtl"] .aside-settings-menu-lg-show.breadcrumb-fixed .breadcrumb {
    left: 350px;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (min-width: 1200px) {

  html:not([dir="rtl"]) .aside-settings-menu-show .aside-settings-menu,
  html:not([dir="rtl"]) .aside-settings-menu-xl-show .aside-settings-menu {
    margin-right: 0;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html:not([dir="rtl"]) .aside-settings-menu-xl-show.aside-settings-menu-fixed .main,
  html:not([dir="rtl"]) .aside-settings-menu-xl-show.aside-settings-menu-fixed .app-footer {
    margin-right: 350px;
  }
  html:not([dir="rtl"]) .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html:not([dir="rtl"]) .aside-settings-menu-xl-show.breadcrumb-fixed .breadcrumb {
    right: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show .aside-settings-menu,
  html[dir="rtl"] .aside-settings-menu-xl-show .aside-settings-menu {
    margin-left: 0;
  }
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-show.aside-settings-menu-fixed .app-footer,
  html[dir="rtl"] .aside-settings-menu-xl-show.aside-settings-menu-fixed .main,
  html[dir="rtl"] .aside-settings-menu-xl-show.aside-settings-menu-fixed .app-footer {
    margin-left: 350px;
  }
  html[dir="rtl"] .aside-settings-menu-show.breadcrumb-fixed .breadcrumb,
  html[dir="rtl"] .aside-settings-menu-xl-show.breadcrumb-fixed .breadcrumb {
    left: 350px;
  }
  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
/***** Preview sticky *******/

.preview-fixed{
  position: fixed;
  top: 60px;
}
.preview-container{
  width: 300px;
}

/** Media queries for preview container **/
@include media-breakpoint-up(md){
  .preview-container{
    width:300px;
  }
}

@include media-breakpoint-up(lg){
  .preview-container{
    width:350px;
  }
}

@include media-breakpoint-up(xl){
  .preview-container{
    width:350px;
  }
}

// Content preview
.settings-preview{
  background-image: url("/assets/img/preview/phone-preview.svg");
  padding: 0.2rem;
  max-width: 100%;
  height: 100%;
  text-align: left;
  background-size: cover;
  .settings-content{
      margin: 82px 22px 72px 22px;
      width: auto;
      max-width: 100%;
      height: 420px;
      overflow-y: scroll;
      overflow-x: hidden;
  }
}
